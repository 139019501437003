<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {
    this.$nextTick(() => {
      // 能源看板不接告警訊息
      if (window.xmppClient) {
        window.xmppClient.stop()
      }
    })
  },
  destroyed() {},
  methods: {}
}
</script>

<style>
html {
  font-size: 1.35vw;
}
body {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.swiper-container {
  height: 100vh;
  .swiper-slide {
    overflow: hidden;
  }
}
*::v-deep {
  .v-card,
  .rounded {
    border-radius: 0.5rem !important;
  }
  .info-card {
    height: 52.25vw;
    margin: 2vw 0 2vw 2vw;
  }
  .main-card {
    height: 100%;
    margin: 2vw;
  }
  .banner {
    text-align: center;
    margin: 2vw;
  }
  .row {
    margin: 0 !important;
    .col {
      padding: 0 !important;
    }
  }
  .message-box {
    background-color: #ffffff;
    display: inline;
    padding: 1.2rem 2.5rem;
    border-radius: 1rem;
    font-size: 1.2rem;
    box-shadow: 0.3rem 0.3rem 0.5rem #83838387;
  }
  .schoolDashboard-card {
    overflow: hidden;
  }
}
</style>
